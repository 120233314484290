export const API_BASE_URL = 'https://dev.api.askmilo.ai'
//export const API_BASE_URL = 'http://192.168.29.54:5500'

export const MEDIA_URL = 'https://zan-rides-assets.s3.af-south-1.amazonaws.com'

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/admin/auth/login`,
    me: `${API_BASE_URL}/api/admin/auth/me`,
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/v1/admin/user/counts`
  },
  CATEGORY: {
    list: `${API_BASE_URL}/api/admin/categories`,
    create: `${API_BASE_URL}/api/admin/categories`,
    edit: (id) => `${API_BASE_URL}/api/admin/categories/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/categories/${id}`
  },
  PROMPT: {
    categoryList: `${API_BASE_URL}/api/users/misc/category`,
    generatePrompt: `${API_BASE_URL}/api/admin/prompt/generate`,
    executePrompt: (id) =>`${API_BASE_URL}/api/admin/prompt/execute/${id}`,
    savePrompt: (id) =>`${API_BASE_URL}/api/admin/prompt/save/${id}`,
    promptList: `${API_BASE_URL}/api/admin/prompt`,
    myPromptList: `${API_BASE_URL}/api/admin/prompt/my`,
    allPromptList: `${API_BASE_URL}/api/admin/prompt`,
    getPromptById: (id) =>`${API_BASE_URL}/api/admin/prompt/${id}`
  },
  // LANGUAGE: {
  //   list: `${API_BASE_URL}/api/v1/admin/languages`,
  //   create: `${API_BASE_URL}/api/v1/admin/languages`,
  //   edit: id => `${API_BASE_URL}/api/v1/admin/languages/${id}`,
  //   delete: id => `${API_BASE_URL}/api/v1/admin/languages/${id}`
  // },
  FAQ: {
    list: `${API_BASE_URL}/api/admin/faqs`,
    create: `${API_BASE_URL}/api/admin/faqs`,
    edit: (id) => `${API_BASE_URL}/api/admin/faqs/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/faqs/${id}`
  },
  BLOG: {
    list: `${API_BASE_URL}/api/admin/blogs`,
    create: `${API_BASE_URL}/api/admin/blogs`,
    edit: (id) => `${API_BASE_URL}/api/admin/blogs/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/blogs/${id}`
  },
  PRIVACY_POLICY: {
    list: `${API_BASE_URL}/api/admin/legalcontent/privacy_policy`,
    edit: `${API_BASE_URL}/api/admin/legalcontent/privacy_policy`
  },
  Terms_AND_CONDITIONS: {
    list: `${API_BASE_URL}/api/admin/legalcontent/terms_and_conditions`,
    edit: `${API_BASE_URL}/api/admin/legalcontent/terms_and_conditions`
  },
  SUBSCRIPTIONS_FOR_PROMPT: {
    list: `${API_BASE_URL}/api/admin/subscriptions`,
    create: `${API_BASE_URL}/api/admin/subscriptions`,
    edit: (id) => `${API_BASE_URL}/api/admin/subscriptions/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/subscriptions/${id}`
  },
  Users: {
    list: `${API_BASE_URL}/api/admin/users`,
    export: `${API_BASE_URL}/api/admin/users/export/excel`
  }
}
